<template>
  <el-dialog :visible="showDetail" @close="handleColse">
    <span slot="title">功能室详情</span>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="label1"><b>功能室名称：</b></label>
          <label>{{ model.Func_Room_Name }}</label>
        </div>
        <div class="form-group">
          <label class="label1"><b>负责人：</b></label>
          <label>{{ model.Man_User_Names }}</label>
        </div>
        <div class="form-group">
          <label class="label1"><b>位置：</b></label>
          <label>{{ model.Location }}</label>
        </div>
        <div class="form-group">
          <label class="label1"><b>设施：</b></label>
          <label>{{ model.Facillities }}</label>
        </div>
        <div class="form-group">
          <label class="label1"><b>教玩具：</b></label>
          <label>{{ model.Room_Tools }}</label>
        </div>
        <div class="form-group">
          <label style="vertical-align: top" class="label1"><b>二维码：</b></label>
          <el-image
              style="width: 150px; height: 150px;"
              :src="'data:image/png;base64,' + model.Room_QrCode"
              :preview-src-list="['data:image/png;base64,' + model.Room_QrCode]">
          </el-image>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button icon="el-icon-check" type="success" @click="handleColse">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "detail",
  props:['showDetail','model'],
  methods:{
    handleColse(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.label1{
  width: 6em;
  text-align: right;
  margin-right: 5px;
}
</style>